<template>
	<div class="col-md-12 box my-2">
		<div class="row align-items-center h-100">
			<div class="col-md-3 d-flex align-items-center">
				<p class="descricao mb-0 mr-3">Data</p>

				<input type="date" class="input_default form-control" v-model="inventary.creationTime" />
			</div>
			<div class="col-md-4 d-flex align-items-center">
				<p class="descricao mb-0 mr-3">Item</p>
				<input type="text" class="input_default form-control" v-model="inventary.item" />
			</div>
			<div class="col-md-3 d-flex align-items-center">
				<p class="descricao mb-0 mr-3">Valor</p>
				<money v-bind="money" class="input_default form-control" v-model="inventary.price" />
			</div>
			<div class="col-md-2 d-flex align-items-center justify-content-end ml-auto" v-if="!load">
				<i class="fas fa-times-circle ml-4 exclude" @click.prevent="$emit('showObs')"></i>
				<i
					class="fas fa-check-circle ml-3 edit"
					@click.prevent="inventary.price > 0 && inventary.item!='' && moment(inventary.creationTime ,'DD-MM-YYYY').isValid() ? newLog() : $notify({group: 'erros',type: 'error',text: `<i class='icon ion-close-circled'></i> Preencha todos os campos corretamente`})"
				></i>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from "@/api_system";
	import { Money } from "v-money";
	import moment from "moment";
	export default {
		components: { Money },
		data() {
			return {
				inventary: {
					item: "",
					price: "",
					creationTime: "",
					userId: this.$store.getters.getUser.id,
				},
				load: false,
				money: {
					decimal: ",",
					thousands: ".",
					prefix: "R$ ",

					precision: 2,
					masked: false,
				},
				moment: moment,
			};
		},
		mounted() {},
		methods: {
			newLog() {
				this.load = true;

				var json = {
					item: this.inventary.item,
					price: this.inventary.price,
					creationTime: this.inventary.creationTime,
					userId: this.$store.getters.getUser.id,
				};

				HTTP.post("services/app/Expense/CreateOrEdit", json, {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				})
					.then((response) => {
						this.inventary = {};
						this.load = false;
						this.$emit("getInventay");
						this.$emit("showObs");
					})
					.catch((error) => {
						this.load = false;
					});
			},
		},
	};
</script>

<style scoped>
.box {
	background: #357eb8;
	border: 1px solid #e0a638;
	height: 75px;
}
.input_default {
	height: 45px;
	color: #ffffff;
	text-align: left;
	background-color: #6198c2 !important;
}
.descricao {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.fas,
.far {
	font-size: 20px;
	cursor: pointer;
	transition: all 0.2s linear;
}
.exclude {
	color: #e67979;
}
.edit {
	color: #35b890;
}
@media screen and (max-width:767px){
	.box{
		height:230px!important;
	}
	.descricao{
		min-width: 13%;
    	max-width: 13%;
	}
}
</style>
